import LandingPage from './components/LandingPage';
import { BrowserRouter as Router, 
Routes,
Route,
}  from 'react-router-dom';

import './App.css';


function App() {
  return (  
    <LandingPage/>

  );
}

export default App;
