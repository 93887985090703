import React, { useState, useEffect } from 'react';
import { Search, Phone, ShoppingCart, ChevronDown, ChevronLeft, ChevronRight, Star, Mail, Facebook, Instagram, Twitter } from 'lucide-react';

const departments = [
  { name: 'כלבים', subDepartments: ['מזון', 'אביזרים', 'טיפוח'] },
  { name: 'חתולים', subDepartments: ['מזון', 'צעצועים', 'תיבות חול'] },
  { name: 'עולם המים', subDepartments: ['אקווריומים', 'דגים', 'ציוד'] },
  { name: 'חיות מחמד קטנות', subDepartments: ['מזון', 'כלובים', 'אביזרים'] },
  { name: 'ציפורים', subDepartments: ['מזון', 'כלובים', 'צעצועים'] }
];

const productImages = [
  { src: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/carouseltop/1.JPG", alt: "מבצעים בחנות", link: "/product/1" },
  { src: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/carouseltop/2.JPG", alt: "מבצעים בחנות", link: "/product/2" },
  { src: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/carouseltop/3.JPG", alt: "מבצעים בחנות", link: "/product/3" },
  { src: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/carouseltop/4.JPG", alt: "מבצעים בחנות", link: "/product/4" },
  { src: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/carouseltop/5.JPG", alt: "מבצעים בחנות", link: "/product/5" },
  { src: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/carouseltop/6.JPG", alt: "מבצעים בחנות", link: "/product/6" }
];

const ProductCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % productImages.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + productImages.length) % productImages.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleImageClick = (e, link) => {
    e.preventDefault();
    // Here you can add logic to navigate to the product page
    console.log(`Navigating to: ${link}`);
  };

  return (
    <div className="w-full bg-gray-200"> {/* Full-width container with background */}
      <div className="relative max-w-[1200px] mx-auto"> {/* Centered container with max-width */}
        <div className="w-full" style={{ paddingTop: '50%' }}> {/* 2:1 aspect ratio container */}
          <div className="absolute top-0 left-0 w-full h-full">
            {productImages.map((image, index) => (
              <div
                key={index}
                className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ease-in-out ${
                  index === currentIndex ? 'opacity-100 z-10' : 'opacity-0 z-0'
                }`}
              >
                <img 
                  src={image.src} 
                  alt={image.alt} 
                  className="w-full h-full object-contain"
                />
                <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <span className="text-white text-lg sm:text-xl md:text-2xl font-bold px-4 text-center">{image.alt}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {/* Navigation Buttons */}
        <button 
          onClick={prevSlide} 
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 transition-all duration-300 z-20"
        >
          <ChevronLeft size={24} className="text-gray-800" />
        </button>
        <button 
          onClick={nextSlide} 
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 transition-all duration-300 z-20"
        >
          <ChevronRight size={24} className="text-gray-800" />
        </button>
      </div>
    </div>
  );
};

const saleProducts = [
  {
    id: 1,
    title: "מזון יבש לכלבים - 10 ק\"ג",
    description: "מזון איכותי עם רכיבים טבעיים, מתאים לכל הגזעים",
    image: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/salessection/Screen+Shot+2024-08-02+at+0.11.04.png", // Replace with actual product image URL
    price: 129.99,
    salePrice: 99.99,
    rating: 4.5,
    reviewCount: 128,
    review: { author: "דני", text: "מזון מעולה, הכלב שלי אוהב אותו מאוד!" }
  },
  {
    id: 2,
    title: "חטיף לעיסה לחתולים",
    description: "מפחית אבנית ומשפר בריאות הפה, עשוי מחומרים בטוחים",
    image: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/salessection/Screen+Shot+2024-08-01+at+23.44.13.png", // Replace with actual product image URL
    price: 16.99,
    salePrice: 12.99,
    rating: 4.8,
    reviewCount: 95,
    review: { author: "מיכל", text: "החתול שלי לא מפסיק לשחק עם זה!" }
  },
  {
    id: 3,
    title: "אקווריום זכוכית - 50 ליטר",
    description: "אקווריום איכותי עם מסנן ותאורת LED מובנית",
    image: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/salessection/Screen+Shot+2024-08-01+at+23.49.38.png", // Replace with actual product image URL
    price: 299.99,
    salePrice: 249.99,
    rating: 4.6,
    reviewCount: 73,
    review: { author: "יוסי", text: "אקווריום מדהים, קל להרכבה ולתחזוקה" }
  },
  {
    id: 4,
    title: "כלוב לתוכים - גודל בינוני",
    description: "כלוב מרווח עם אביזרים, מתאים לזוג תוכים",
    image: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/salessection/Screen+Shot+2024-08-01+at+23.52.18.png", // Replace with actual product image URL
    price: 3120.99,
    salePrice: 159.99,
    rating: 4.3,
    reviewCount: 52,
    review: { author: "רונית", text: "כלוב איכותי, התוכים שלי מאושרים בו" }
  },
  {
    id: 5,
    title: "מיטה לכלב - גודל גדול",
    description: "מיטה נוחה ורכה, מתאימה לכלבים גדולים עד 40 ק\"ג",
    image: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/salessection/Screen+Shot+2024-08-01+at+23.54.08.png", // Replace with actual product image URL
    price: 149.99,
    salePrice: 119.99,
    rating: 4.7,
    reviewCount: 89,
    review: { author: "אבי", text: "הכלב שלי לא רוצה לקום מהמיטה הזו!" }
  },
  {
    id: 6,
    title: "מזון לדגי אקווריום - 1 ק\"ג",
    description: "מזון צף איכותי, מתאים לכל סוגי דגי האקווריום",
    image: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/salessection/Screen+Shot+2024-08-01+at+23.56.46.png", // Replace with actual product image URL
    price: 59.99,
    salePrice: 49.99,
    rating: 4.4,
    reviewCount: 106,
    review: { author: "גלי", text: "הדגים שלי אוהבים את המזון הזה, צבעים יפים יותר" }
  },
  {
    id: 7,
    title: "תיק נשיאה לחתול",
    description: "תיק נוח ובטיחותי לנשיאת חתולים, מתאים לטיולים וביקורים אצל הווטרינר",
    image: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/salessection/Screen+Shot+2024-08-02+at+0.25.15.png", // Replace with actual product image URL
    price: 89.99,
    salePrice: 69.99,
    rating: 4.2,
    reviewCount: 64,
    review: { author: "דנה", text: "תיק מעולה, החתול שלי נרגע בו במהירות" }
  },
  {
    id: 8,
    title: "כלוב עם גלגל ריצה לאוגרים",
    description: "כלוב עם גלגל ריצה שקט ובטיחותי, מספק פעילות גופנית לאוגרים",
    image: "https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/salessection/Screen+Shot+2024-08-02+at+0.26.12.png", // Replace with actual product image URL
    price: 84.99,
    salePrice: 61.99,
    rating: 4.5,
    reviewCount: 81,
    review: { author: "עומר", text: "האוגר שלי מבלה שעות על הגלגל הזה, ממליץ בחום" }
  }
];

const reviews = [
  { id: 1, name: "דנה כהן", rating: 5, comment: "השירות מעולה והמוצרים באיכות גבוהה. תמיד מוצאת כאן הכל לחיות המחמד שלי." },
  { id: 2, name: "יוסי לוי", rating: 4, comment: "מחירים הוגנים ומשלוח מהיר. נהנה לקנות כאן באופן קבוע." },
  { id: 3, name: "מיכל ברק", rating: 5, comment: "הצוות מקצועי ותמיד עוזר בבחירת המוצר הנכון. ממליצה בחום!" },
  { id: 4, name: "אבי שמעוני", rating: 4, comment: "מגוון רחב של מוצרים. קל למצוא כל מה שצריך במקום אחד." },
  { id: 5, name: "רונית דביר", rating: 5, comment: "איכות השירות יוצאת מן הכלל. תמיד חוזרת לקנות כאן." },
];




const ProductCatalog = () => (
  <div className="py-8 sm:py-12" dir="rtl">
    <div className="container mx-auto px-4 max-w-7xl">
      <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center">מבצעים מיוחדים</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4">
        {saleProducts.map((product) => (
          <div key={product.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="relative pb-[75%]">
              <img 
                src={product.image} 
                alt={product.title} 
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
              <span className="absolute top-1 right-1 bg-red-600 text-white text-xs font-bold py-1 px-2 rounded-full">מבצע</span>
            </div>
            <div className="p-2 sm:p-3">
              <h3 className="font-bold text-xs sm:text-sm mb-1 text-right truncate">{product.title}</h3>
              <p className="text-gray-600 text-xs mb-1 sm:mb-2 text-right line-clamp-2">{product.description}</p>
              <div className="flex flex-row-reverse justify-between items-center mb-1">
                <div className="flex items-center">
                  <span className="text-yellow-500 text-xs ml-1">{product.rating.toFixed(1)}</span>
                  <div className="flex">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} size={10} fill={i < Math.floor(product.rating) ? "#FFD700" : "#E5E7EB"} color="#FFD700" />
                    ))}
                  </div>
                </div>
                <span className="text-gray-500 text-xs">({product.reviewCount})</span>
              </div>
              <div className="flex justify-between items-center mt-1 sm:mt-2">
                <span className="text-gray-500 line-through text-xs">₪{product.price.toFixed(2)}</span>
                <span className="text-red-600 font-bold text-xs sm:text-sm">₪{product.salePrice.toFixed(2)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const categories = [
  { name: 'כלבים', image: 'https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/categorrysectionassets/Screen+Shot+2024-08-02+at+0.02.54.png' }, // Replace with actual dog image URL
  { name: 'חתולים', image: 'https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/categorrysectionassets/Screen+Shot+2024-08-02+at+0.04.59.png' }, // Replace with actual cat image URL
  { name: 'דגים', image: 'https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/categorrysectionassets/Screen+Shot+2024-08-02+at+0.07.02.png' }, // Replace with actual fish image URL
  { name: 'ציפורים', image: 'https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/categorrysectionassets/Screen+Shot+2024-08-02+at+0.06.22.png' }, // Replace with actual bird image URL
  { name: 'מכרסמים', image: 'https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/categorrysectionassets/Screen+Shot+2024-08-02+at+0.05.36.png' }, // Replace with actual rodent image URL
];

const CategorySection = () => (
  <div className="py-12 bg-gray-100" dir="rtl">
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-6 text-center">קטגוריות מוצרים</h2>
      <div className="flex flex-wrap -mx-4 justify-center">
        {categories.map((category, index) => (
          <div key={index} className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 px-4 mb-8">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <img src={category.image} alt={category.name} className="w-full h-40 object-cover" />
              <div className="p-4">
                <h3 className="font-bold text-lg text-center">{category.name}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const petTips = [
  {
    category: 'כלבים',
    image: 'https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/tipssection/Screen+Shot+2024-08-02+at+0.32.49.png',
    title: 'טיפים חשובים לבעלי כלבים',
    tips: [
      'האכילו את כלבכם 2-3 פעמים ביום בכמויות מדודות',
      'ודאו שיש לכלב גישה למים טריים בכל עת',
      'טיילו עם כלבכם לפחות 30 דקות ביום',
      'בצעו ביקורת וטרינר שנתית לשמירה על בריאות הכלב'
    ]
  },
  {
    category: 'חתולים',
    image: 'https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/tipssection/Screen+Shot+2024-08-02+at+0.44.13.png',
    title: 'כיצד לטפל בחתול שלכם',
    tips: [
      'ספקו לחתול שלכם מזון עשיר בחלבון',
      'נקו את ארגז החול לפחות פעם ביום',
      'שחקו עם החתול שלכם כדי לגרות אותו מנטלית ופיזית',
      'הקפידו על סירוק פרווה קבוע למניעת כדורי שיער'
    ]
  },
  {
    category: 'דגים',
    image: 'https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/tipssection/Screen+Shot+2024-08-02+at+0.45.05.png',
    title: 'טיפול נכון באקווריום',
    tips: [
      'שמרו על טמפרטורת המים קבועה',
      'החליפו 25% מהמים באקווריום מדי שבוע',
      'האכילו את הדגים בכמויות קטנות מספר פעמים ביום',
      'בדקו את איכות המים באופן קבוע'
    ]
  },
  {
    category: 'ציפורים',
    image: 'https://webinfotailoredapps.s3.eu-north-1.amazonaws.com/petshopstoreassets/tipssection/Screen+Shot+2024-08-02+at+0.45.27.png',
    title: 'עצות לטיפול בציפורי מחמד',
    tips: [
      'ספקו לציפור שלכם תזונה מגוונת הכוללת זרעים, פירות וירקות',
      'נקו את הכלוב לפחות פעם בשבוע',
      'אפשרו לציפור זמן מחוץ לכלוב בסביבה בטוחה',
      'הציעו לציפור צעצועים ופעילויות לגירוי מנטלי'
    ]
  }
];

const PetTipsBlogSection = () => (
  <div className="py-8" dir="rtl" >
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">טיפים לטיפול בחיות מחמד</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {petTips.map((pet, index) => (
          <div key={index} className="bg-white rounded-lg shadow-sm overflow-hidden flex">
            <img src={pet.image} alt={pet.category} className="w-24 h-24 object-cover" />
            <div className="p-4 flex-1">
              <h3 className="text-lg font-bold mb-1 text-blue-600">{pet.title}</h3>
              <p className="text-xs text-gray-600 mb-2">{pet.category}</p>
              <ul className="text-sm space-y-1">
                {pet.tips.map((tip, tipIndex) => (
                  <li key={tipIndex} className="flex items-start">
                    <span className="text-blue-500 ml-1">•</span>
                    <span className="text-gray-700">{tip}</span>
                  </li>
                ))}
              </ul>
              <a href="#" className="inline-block mt-2 text-sm text-blue-600 hover:text-blue-800 transition duration-300">
                קרא עוד...
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const ReviewSection = () => (
  <div className="bg-gray-100 py-8" dir="rtl">
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-6 text-center">מה הלקוחות שלנו אומרים</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {reviews.map((review) => (
          <div key={review.id} className="bg-white rounded-lg shadow-md p-3 flex flex-col" style={{ height: '200px' }}>
            <div className="flex-1 flex flex-col justify-evenly">
              <div className="flex justify-center">
                {[...Array(5)].map((_, i) => (
                  <Star 
                    key={i} 
                    size={16} 
                    fill={i < review.rating ? "#FFD700" : "#E5E7EB"} 
                    color="#FFD700" 
                  />
                ))}
              </div>
              <p className="text-gray-600 text-right text-sm leading-snug overflow-ellipsis line-clamp-3">{review.comment}</p>
            </div>
            <div className="text-right font-bold text-sm text-gray-800 mt-2">
              - {review.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const SubscriptionForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Subscription submitted:', { name, email });
    setName('');
    setEmail('');
  };

  return (
    <div className="bg-blue-600 text-white py-12" dir="rtl">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold mb-4 text-center">הצטרפו למועדון הלקוחות שלנו</h2>
        <p className="text-center mb-6">קבלו מבצעים בלעדיים והנחות מיוחדות ישירות לתיבת הדואר שלכם</p>
        <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-4">
          <div className="flex flex-col md:flex-row justify-center items-center w-full max-w-2xl space-y-4 md:space-y-0 md:space-x-4 md:space-x-reverse">
            <input
              type="text"
              placeholder="שם מלא"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full md:w-auto px-4 py-2 rounded-md text-gray-800 text-right"
              required
            />
            <input
              type="email"
              placeholder="כתובת אימייל"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full md:w-auto px-4 py-2 rounded-md text-gray-800 text-right"
              required
            />
          </div>
          <button type="submit" className="px-6 py-2 bg-green-500 hover:bg-green-600 rounded-md transition duration-300">
            הרשמה
          </button>
        </form>
      </div>
    </div>
  );
};

const Footer = () => (
  <footer className="bg-gray-800 text-white py-8" dir="rtl">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <h3 className="text-lg font-bold mb-4">אודותינו</h3>
          <p className="text-right">חנות החיות שלנו מספקת מוצרים איכותיים לחיות מחמד מאז 1990. אנו מחויבים לבריאות ולרווחה של חיות המחמד שלכם.</p>
        </div>
        <div>
          <h3 className="text-lg font-bold mb-4">קישורים מהירים</h3>
          <ul className="text-right">
            <li><a href="#" className="hover:text-blue-400">מדיניות משלוחים</a></li>
            <li><a href="#" className="hover:text-blue-400">מדיניות החזרות</a></li>
            <li><a href="#" className="hover:text-blue-400">שאלות נפוצות</a></li>
            <li><a href="#" className="hover:text-blue-400">צור קשר</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-bold mb-4">עקבו אחרינו</h3>
          <div className="flex justify-start space-x-4 space-x-reverse">
            <a href="#" className="hover:text-blue-400"><Facebook /></a>
            <a href="#" className="hover:text-blue-400"><Instagram /></a>
            <a href="#" className="hover:text-blue-400"><Twitter /></a>
          </div>
        </div>
      </div>
      <div className="mt-8 text-center">
        <p> עוצב ויוצר על ידי חברת TailoredApps &copy;</p>
      </div>
    </div>
  </footer>
);

const Navbar = () => {
  const [openDepartment, setOpenDepartment] = useState(null);

  const handleMouseEnter = (index) => {
    setOpenDepartment(index);
  };

  const handleMouseLeave = () => {
    setOpenDepartment(null);
  };

  return (
     <div className="flex flex-col min-h-screen">
      <nav className="bg-white text-gray-800 shadow-md relative z-50" dir="rtl"> {/* Added relative and z-50 */}
        <div className="container mx-auto px-4">
          {/* Top bar */}
          <div className="flex justify-between items-center py-4">
            {/* Logo */}
            <div className="flex-1">
              <span className="text-2xl font-bold text-blue-600">לוגו חנות החיות</span>
            </div>

          {/* Contact Information */}
            <div className="flex-1 flex flex-col items-center justify-center">
              <span className="text-sm font-medium text-gray-600 mb-1">שירות לקוחות</span>
              <div className="flex items-center space-x-2 space-x-reverse">
                <Phone className="text-blue-600" size={20} />
                <span className="text-sm">7890-456 (123)</span>
              </div>
            </div>

            {/* Search Bar and Cart */}
            <div className="flex-1 flex justify-end items-center space-x-4 space-x-reverse">
              <div className="relative flex-grow max-w-xs">
                <input
                  type="text"
                  placeholder="חיפוש..."
                  className="w-full py-2 px-4 rounded-full bg-gray-100 text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-600 text-right"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
              </div>
              <ShoppingCart className="text-blue-600 cursor-pointer flex-shrink-0" size={24} />
            </div>
          </div>

        {/* Departments */}
           <div className="flex flex-wrap justify-between items-center py-4">
            {departments.map((dept, index) => (
              <div 
                key={index} 
                className="relative group flex-1 min-w-[120px] text-center mb-2"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <button className="w-full py-2 px-4 rounded-md hover:bg-gray-100 transition duration-200">
                  <span>{dept.name}</span>
                  <ChevronDown size={16} className="inline-block mr-1" />
                </button>
                {openDepartment === index && (
                  <div className="absolute top-full right-0 bg-white shadow-md rounded-md py-2 z-50 w-full"> {/* Changed z-index to z-50 */}
                    {dept.subDepartments.map((subDept, subIndex) => (
                      <a key={subIndex} href="#" className="block px-4 py-2 hover:bg-gray-100">
                        {subDept}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </nav>

      {/* Full-width Product Carousel */}
      <ProductCarousel />
      
      {/* Product Catalog */}
      <ProductCatalog />
      <CategorySection />
       
    {/* Review Section */}
      <ReviewSection />
      <PetTipsBlogSection />
      {/* Subscription Form */}
      <SubscriptionForm />
      
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Navbar;